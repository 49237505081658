<template>
  <div id="wrapper" :class="(this.$route.name == 'home' || this.$route.name == 'news_detail' || this.$route.name == 'main_news_detail') ? 'homepage_body_bg' : (this.$route.name.includes('match_detail') || this.$route.name.includes('matchDetail')) ? 'listdtlpage_body_bg' : (this.$route.name.includes('video'))?'sabc_tier_sports_page_body video_page':'sabc_tier_sports_page_body'">
    <div class="header-bg-setting"></div>
    <span v-if="this.$route.name != 'cricketpanel'" class="homepage_body_bg_yellow"></span>
    <span v-if="this.$route.name != 'cricketpanel'" class="homepage_body_bg_green"></span>
    <div class="body-header">
      <app-header v-if="this.$route.name != 'cricketpanel'"></app-header>
      <router-view :key="reload"/>
    </div>
    <app-footer v-if="this.$route.name != 'cricketpanel'"></app-footer>
  </div>
</template>

<script>
import appHeader from '../common/header'
import appFooter from '../common/footer'

export default {
  name: 'defaultLayout',
  data() {
    return {}
  },
  components: {
    appHeader, appFooter
  },
  mounted() {
  },
  methods: {}
}
</script>
<style lang="scss">
@import '~@/assets/scss/bootstrap.scss';
@import '~@/assets/scss/vendor.scss';
@import '~@/assets/scss/fonts.scss';
</style>
