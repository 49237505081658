<template>
    <div>
        <!-- header start -->
        <header class="sbac_header">
            <div class="container">
                <!-- header ad and registration -->
                <div class="sbac_header_top sbac_header_top_desk">
                    <div class="sbac_header_top_ad">
                        <!-- <a href="/live/football/league-detail/world-cup-group-a/56436/fixtures">
                            <picture>
                              <source media="(min-width: 991px)" srcset="@/assets/images/sbac_header_top_banner.webp"  alt="Header Top Banner" title="Header Top Banner" loading="lazy" height="160" width="950" />
                              <img src="@/assets/images/ftb_lineup_bottom_arrow.webp" alt="Header Top Banner" title="Header Top Banner" loading="lazy" height="12" width="6" />
                            </picture>
                        </a> -->
                    </div>
                    <!-- <div class="sbac_header_top_profile">
                        <router-link :to="{name: 'home'}">
                            <img :src="require('@/assets/images/sbac_header_top_profile.webp')"
                                alt="sbac_header_top_profile" title="sbac_header_top_profile" height="34" width="34" loading="lazy"/>
                        </router-link>
                    </div>
                    <div class="sbac_header_top_reg_login">
                        <ul>
                            <li>
                                <a href="#">Log in</a>
                            </li>
                            <li>
                                <a href="#">Register</a>
                            </li>
                        </ul>
                    </div> -->
                </div>
                <!-- header navbar and logo -->
                <div class="sbac_header_navbar">
                    <nav class="navbar navbar-expand-lg">
                        <div class="sbac_header_brand">
                            <router-link :to="'/'">
                                <img :src="require('@/assets/images/sabc_main_logo.webp')" alt="Main Logo" title="Main Logo" height="80" width="249"/>
                            </router-link>
                        </div>
                        <div class="sbac_hdr_watchvideobtn sbac_hdr_top_watchvideobtn_mobi">
                            <router-link class="wacthbtn" :to="{ name: 'tv_live'}">
                                <i>
                                <img :src="require('@/assets/images/sbac_hdr_watchvideobtn.webp')" alt="sbac_hdr_watchvideobtn" title="sbac_hdr_watchvideobtn" height="23" width="23"/>
                                </i>
                                Watch SABC Sport
                            </router-link>
                        </div>
                        <button @click="addClass" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" class="navbar-toggler" data-target="#navbarSupportedContent" data-toggle="collapse" type="button">
                          <span class="navbar-toggler-icon">
                              <img :src="require('@/assets/images/sabc_burger_menu_icon.svg')" alt="sabc_burger_menu_icon" title="sabc_burger_menu_icon" width="24" height="18" />
                          </span>
                        </button>
                        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                            <div class="sabc_navbar_alignment">
                                <div class="sabc_header_navbar_mobi">
                                    <div class="sbac_header_brand sbac_header_brand_mobi">
                                        <a href="/">
                                            <img :src="require('@/assets/images/sabc_main_logo.webp')" alt="Main Logo" title="Main Logo" height="31" width="123"/>
                                        </a>
                                        <a @click="removeClass" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" class="sabc_close_burgermenu" data-target="#navbarSupportedContent" data-toggle="collapse" href="#"></a>
                                    </div>
                                    <div class="sbac_hdr_watchvideobtn sbac_hdr_watchvideobtn_mobi">
                                      <a class="sabcpluus" href="https://sabc-plus.com/" target="_blank">
                                            <img :src="require('@/assets/images/sabc_main_logo.svg')" alt="SABC+" title="SABC+" height="15" width="70"/>
                                      </a>
                                      <router-link class="wacthbtn" :to="{ name: 'tv_live'}">
                                          <i>
                                            <img :src="require('@/assets/images/sbac_hdr_watchvideobtn.webp')" alt="sbac_hdr_watchvideobtn" title="sbac_hdr_watchvideobtn" height="23" width="23"/>
                                          </i>
                                          Watch SABC Sport
                                      </router-link>
                                    </div>
                                    <ul class="navbar-nav mr-auto">
                                        <li class="nav-item" v-for="item in navbar" :key="item.id" :class="(item.hasOwnProperty('childs')) ? 'dropdown' : (item.url_path == $route.path) ? 'active': '' ">
                                            <a v-if="item.hasOwnProperty('childs')" aria-expanded="false" aria-haspopup="true" class="nav-link dropdown-toggle" data-toggle="dropdown" href="javascript:;" role="button">{{item.title}}</a>
                                            <router-link v-else class="nav-link" :to="item.url_path">{{item.title}}</router-link>
                                            <div v-if="item.hasOwnProperty('childs')" class="dropdown-menu">
                                                <ul>
                                                    <li v-for="subitem in item.childs" :key="subitem.id">
                                                        <a class="sub-menu" :href="subitem.url_path" target="_blank" v-if="subitem.is_premium">{{subitem.title}}</a>
                                                        <router-link class="sub-menu" :to="{ name: 'sport_home', params: {sport: 'other', news:  'news'}} " v-else-if="subitem.title == 'Main Domain'">Other</router-link>
                                                        <router-link class="sub-menu" :to="subitem.childs[0].url_path" v-else-if="subitem.hasOwnProperty('childs')">{{subitem.title}}</router-link>
                                                        <router-link class="sub-menu" :to="subitem.url_path" v-else-if="item.title != 'Scores'" >{{subitem.title}}</router-link>
                                                        <router-link class="sub-menu" v-else :to="subitem.url_path+currentData" >{{subitem.title}}</router-link>
                                                        <!-- <span v-if="subitem.is_premium">
                                                            <a class="sub-menu" :href="subitem.url_path" target="_blank">{{subitem.title}}</a>
                                                        </span>
                                                        <span v-else>
                                                            <span v-if="item.title != 'Scores'">
                                                            <router-link class="sub-menu" :to="subitem.childs[0].url_path" v-if="subitem.title == 'Football'" >Soccer</router-link>
                                                            <router-link class="sub-menu" :to="subitem.childs[0].url_path" v-else-if="subitem.hasOwnProperty('childs') && subitem.title != 'Main Domain'">{{subitem.title}}</router-link>
                                                            <router-link class="sub-menu" :to="{ name: 'sport_home', params: {sport: 'other', news:  'news'}} " v-else-if="subitem.title == 'Main Domain'">Other</router-link>
                                                            <router-link class="sub-menu" :to="subitem.url_path" v-else-if="subitem.title != 'Main Domain'" >{{subitem.title}}</router-link>
                                                        </span>
                                                        <span v-else>
                                                            <router-link class="sub-menu" v-if="subitem.title == 'Football Scores'" :to="subitem.url_path+currentData" >Soccer Scores</router-link>
                                                            <router-link class="sub-menu" v-else :to="subitem.url_path+currentData" >{{subitem.title}}</router-link>
                                                        </span>
                                                        </span> -->
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <!-- <div class="sbac_header_top sbac_header_top_mobi">
                                    <div class="sbac_header_top_profile">
                                        <a href="#">
                                            <img :src="require('@/assets/images/sbac_header_top_profile.webp')"
                                                 alt="sbac_header_top_profile" title="sbac_header_top_profile" height="34" width="34" loading="lazy"/>
                                        </a>
                                    </div>
                                    <div class="sbac_header_top_reg_login">
                                        <ul>
                                            <li>
                                                <a href="#">Log in</a>
                                            </li>
                                            <li>
                                                <a href="#">Register</a>
                                            </li>
                                        </ul>
                                    </div> 
                                </div>-->
                                <a class="sabc_plus_logo_mobi_hide" href="https://sabc-plus.com/" target="_blank" :style="(navbar.length<7)?'margin-left: 65px;':''">
                                    <img :src="require('@/assets/images/sabc_main_logo.svg')" alt="SABC+" title="SABC+" height="15" width="100"/>
                                </a>
                                <div class="sbac_hdr_watchvideobtn sbac_hdr_watchvideobtn_desk">
                                    <router-link class="wacthbtn" :to="{ name: 'tv_live'}">
                                        <i>
                                          <img :src="require('@/assets/images/sbac_hdr_watchvideobtn.webp')" alt="sbac_hdr_watchvideobtn" title="sbac_hdr_watchvideobtn" height="23" width="23"/>
                                        </i>
                                        Watch SABC Sport
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </header>
        <!-- 404 content setting along with page builder template -->
        <div v-if="hasPageBuilder > 0">
            <div class="page-builder-area"></div>
            <pageBuilder v-if="hasPageBuilder != -1" :hasPageBuilder="hasPageBuilder"></pageBuilder>
        </div>
        <div v-if="hasPageBuilder == 0 && this.$route.name == 'pageNotFound'" class="404Found">
            <h1 class="title-404">Oops! 404</h1>
            <div class="content-404 sub-lineheight">
                <p>The page you are looking for might have been removed, had its name changed or is temporarily unavailable</p>
            </div>
        </div>
    </div>
</template>

<script>
    import pageBuilder from '../common/page-builder'

    export default {
        name: 'Header',

        jsonld() {
            return {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name" : 'SABC Sport',
                "url": this.$siteURL,
                "logo": {
                    "@type": "ImageObject",
                    "url": this.$siteURL + 'public/images/logo.png',
                    "width": 170,
                    "height": 89
                }
            }
        },

        data() {
            return {
                findQuery: '',
                navbar: [],
                hasPageBuilder: 0,
                currentData : 'matches/all/'+new Date().toISOString().slice(0, 10),
            }
        },
        components: {pageBuilder},
        mounted() {
            this.getNavbarData()
        },
       watch: {
           $route(to, from) {
              var pathNameStr = 'matchDetail, match_detail,rugby_match_detail';
              //scroll to current y position for above routes which are football,cricket and rugby match detail pages
              if((to.matched.length > 1 && from.matched.length > 1)  && (pathNameStr.includes(to.matched[1].name) && pathNameStr.includes(from.matched[1].name))){
                window.scroll({x: 0,y: window.pageYOffset})
              }else if((to.name == 'sport_home_competition' || from.name == 'sport_home_competition')){
                window.scroll({x: 0,y: window.pageYOffset})
              }
               else {
                window.scroll({top: 0,left: 0})
              }
              document.getElementById('navbarSupportedContent').classList.remove('show');
              this.getNavbarData();
              document.getElementsByTagName('html')[0].classList.remove('n-scroll');
            }
       },
        serverPrefetch() {
            return this.getNavbarData();
        },
        methods: {
            find() {
                if (this.findQuery) {
                    this.$router.push({name: 'find', query: {q: this.findQuery}}).catch(() => {})
                }
            },
            addClass() {
                document.getElementsByTagName('html')[0].classList.add('n-scroll');
            },
            removeClass() {
                document.getElementsByTagName('html')[0].classList.remove('n-scroll');
            },
            moment: function () {
                return moment();
            },
            getNavbarData(){
                let params = {path: this.$route.fullPath};
                this.hasPageBuilder = 0;
                // return this.$axios.get(this.$GetNavbarData, {params})
                return this.$prepareAPICall(this.$GetNavbarData, params)
                        .then(response => {
                            if (response.data) {
                                this.navbar = response.data.navbar;
                                this.hasPageBuilder = response.data.hasPageBuilder;
                            }
                        })
                        .catch(error => {
                        })
            }
        }
    }
</script>
<style lang="scss">
@import '~@/assets/scss/header.scss';
</style>
